<template>
  <div class="crm-today">
    <div>
      <ve-table
        v-if="!loading"
        :columns="columns" 
        :table-data="tableData"
        style="width:100%" 
        :scroll-width="400"
      />
      <div v-show="tableData.length == 0 && !loading" class="v-table-empty-data">暂无数据</div>
    </div>
    <loadings :loading='loading' />
    <copyright />
  </div>
</template>
<script>

import Loadings from '@/components/loading'
import Copyright from '@/components/Copyright'
export default {
  name: 'AnalysisContract',
  data() {
    return {
      loading:false,
      tableData: [],
      columns: [
          {field: 'rank', key:'rank', title: '排名', width: 60, fixed: "left"},
          {field: 'studio_name', key:'studio_name', title: '校区名称', width: 140, fixed: "left",ellipsis: {showTitle: true},},
          {field: 'contract_money_count', key:'contract_money_count', title: '金额', width: 90, },
          {field: 'contract_count', key:'contract_count', title: '合同', width: 60, },

          {field: 'new_contract_money_count', key:'new_contract_money_count', title: '新签金额', width: 90, },
          {field: 'new_contract_count', key:'new_contract_count', title: '新合同', width: 70, },

          {field: 'renew_contract_money_count', key:'renew_contract_money_count', title: '续费金额', width: 90, },
          {field: 'renew_contract_count', key:'renew_contract_count', title: '续合同', width: 70, },
      ]
    }
  },
  components: {
    Copyright,
    loadings:Loadings,
    // VTable,
    // VPagination,
  },
  created() {
    this.getchartDatas()
  },
  methods: {
    getchartDatas() {
      this.loading = true
      const parentPageObj = this.$parent
      const start_date = parentPageObj.startDate !== '' ? parentPageObj.startDate : ''
      const end_date = parentPageObj.endDate !== '' ? parentPageObj.endDate : ''
      const studio_id = parentPageObj.studio.value !== -1 ? parentPageObj.studio.value : ''
      this.$api.analysis_campus_contract({ start_date, end_date, studio_id })
        .then(res => {
          this.tableData = res.data
          let tableData = res.data
          let total = 0
          let contract_money_count = 0
          let contract_count=0
          let new_contract_money_count=0
          let new_contract_count=0
          let renew_contract_money_count=0
          let renew_contract_count=0
          tableData.forEach(item=>{
            // total = (Number(total) + Number(item.total)).toFixed(2)
            contract_money_count = (Number(contract_money_count) + Number(item.contract_money_count)).toFixed(2)
            contract_count = (Number(contract_count) + Number(item.contract_count))

            new_contract_money_count = (Number(new_contract_money_count) + Number(item.new_contract_money_count))
            new_contract_count = (Number(new_contract_count) + Number(item.new_contract_count))
            renew_contract_money_count = (Number(renew_contract_money_count) + Number(item.renew_contract_money_count))
            renew_contract_count = (Number(renew_contract_count) + Number(item.renew_contract_count))
          })
          let obj ={
            rank:'--',
            studio_name:'合计',
            contract_money_count,
            contract_count,
            new_contract_money_count,
            new_contract_count,
            renew_contract_money_count,
            renew_contract_count
          }
          this.tableData.push(obj)
          localStorage.removeItem('crm_start_date')
          localStorage.removeItem('crm_end_date')
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
    .copyright{
      margin-top: 25px;
    }
    .chart-box{
        box-sizing: border-box;
        background: #fff;
        margin-bottom: 20px;
        padding: 0 15px;
    }

    .chart{
        &-title{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            font-size: 16px;
            color: #333;
            font-weight: bold;
        }
        &-right{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            .section-commend{
              border: 1px solid #00DE93;
              border-radius: 16px;
              overflow: hidden;
            }
            .van-tag::after{
              border: none;
              border-radius:none;
            }
            .van-tag{
                padding: 4px 10px;
                font-size: 14px;
                border-radius: 0;
            }
        }
    }
</style>

<style lang="less">
  .ve-table-border-around{
    border: 0px !important;
  }
  .v-table-empty-data{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 100%;
    color: #666;
    font-size: 14px;
    border: 1px solid #eee;
    border-top: 0;
  }
</style>

