<template>
  <div ref="gwn" class="gwn">
    <van-tabs v-model="tabActive" class="mb10">
      <van-tab v-for="(item,index) in tabs" :key="index" :title="item.name" />
    </van-tabs>
    <div class="mb10" style="position:relative;z-index: 1;">
      <div class="section-row">
        <div class="section-studio" @click="onStudioPickerOpen">
          <span class="van-ellipsis">{{ studio.label }}</span>
          <van-icon name="arrow-down" color="#666" />
        </div>
        <div class="section-date">
          <div class="section-date-start" @click="onDatePickerOpen('startDate')">
            {{ startDate }}
          </div>
          <div class="section-date-end" @click="onDatePickerOpen('endDate')">
            {{ endDate }}
          </div>
        </div>
      </div>
      <!-- <div class="section-row2">
        <div class="section-commend">
          <van-tag v-for="(item,index) in tagList" :key="index" class="section-commend-tag" :plain="index !== tagActive" type="primary" @click="onTagClick(index)">{{ item.label }}</van-tag>
        </div>
      </div> -->
    </div>
    <!-- <van-field
      readonly
      clickable
      :value="startDate"
      placeholder="选择开始时间"
      @click="onDatePickerOpen('startDate')"
    />
    <van-field
      readonly
      clickable
      :value="endDate"
      placeholder="选择结束时间"
      @click="onDatePickerOpen('endDate')"
    /> -->

    <van-popup v-model="dateShowPicker" position="bottom">
      <van-datetime-picker
        v-model="currentTime"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="onDatePickerConfirm"
        @cancel="onDatePickerClose"
      />
    </van-popup>
    <van-popup v-model="studioShowPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="studioColumns"
        value-key="label"
        @cancel="onStudioPickerClose"
        @confirm="onStudioPickerConfirm"
      />
    </van-popup>

    <!-- 宫格展示 start-->
    <!-- <van-grid :column-num="3">
      <van-grid-item v-for="(item,index) in gridList" :key="index" :text="String(item.value)">
        <template #icon>
          <span class="grid-title">{{ item.title }}</span>
        </template>
      </van-grid-item>
    </van-grid> -->
    <!-- 宫格展示 end-->
    <div class="tab-container">
      <component :is="tabs[tabActive].componentName" :ref="tabs[tabActive].componentName" />
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Tab, Tabs, Field, DatetimePicker, Picker, Tag, Grid, GridItem } from 'vant'

import AnalysisContract from './components/contract'
import AnalysisMkt from './components/mkt'
import AnalysisCourse from './components/course'
import AnalysisCrm from './components/crm'
import AnalysisRanking from './components/ranking'
import AnalysisTeacher from './components/teacher'
import dayjs from 'dayjs'
import gwm from 'gwm'

export default {
  name: 'CampusAnalysis',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Tag.name]: Tag,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    AnalysisContract, 
    AnalysisMkt,
    AnalysisCourse,
    AnalysisCrm,
    AnalysisRanking,
    AnalysisTeacher
  },
  mixins: [PageMixin],
  data() {
    const nowDate = dayjs(nowDate).format('YYYY-MM-DD')
    const startYear = dayjs().startOf('year').format('YYYY-MM-DD')
    const startMonth = dayjs().startOf('month').format('YYYY-MM-DD')
    const startWeek = dayjs().startOf('week').format('YYYY-MM-DD')
    return {
      tabs: [
        { name: '签约统计', componentName: 'AnalysisContract', grid: 'analysis_getcontractcount' },
        { name: '业绩统计', componentName: 'AnalysisRanking', grid: 'analysis_getcontractcount' },
        { name: '市场统计', componentName: 'AnalysisMkt', grid: 'analysis_getleadscount' },
        { name: '课消统计', componentName: 'AnalysisCourse', grid: 'analysis_getdemocount' },
        { name: '授课统计', componentName: 'AnalysisTeacher', grid: 'analysis_getdemocount' },
        { name: 'CRM统计', componentName: 'AnalysisCrm', grid: 'analysis_getschedulecount' }
      ],
      tabActive: 0,
      tagList: [{ label: '本周', startDate: startWeek, endDate: nowDate }, { label: '本月', value: 'month', startDate: startMonth, endDate: nowDate }, { label: '本年', value: 'year', startDate: startYear, endDate: nowDate }],
      tagActive: 0,
      gridList: [],
      //
      dateType: 'startDate', // startDate,endDate
      startDate: nowDate,
      endDate: nowDate,
      currentTime:'',
      currentStart: dayjs().startOf('month').$d,
      currentEnd: new Date(),
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(),
      dateShowPicker: false,
      // 校区
      studio: { label: '全部校区', value: -1 },
      studioColumns: [],
      studioShowPicker: false

    }
  },
  created() {
    if(this.$route.query.start_date){
      this.startDate = this.$route.query.start_date
    }
    if(this.$route.query.end_date){
      this.endDate = this.$route.query.end_date
    }
    if(this.$route.query.tab){
      this.tabActive = Number(this.$route.query.tab) - 1
    }
    this.getStudio()
  },
  mounted() {
    this.getEmployee()
  },
  methods: {
    getEmployee(){
      this.$api.employee_index().then(res => {
        localStorage.setItem('employee_watermark',res.data.employee.employee_watermark)
        this.$nextTick(()=>{
          gwm.creation({
            container:this.$refs.gwn,
            txt:localStorage.getItem('employee_watermark'),
            mode: 'svg',
            watch: false,
            fontSize: 11,
            height:80,
            x: 20,
            y: 30,
            color: '#bbbfca',
            font: 'sans-serif',
            alpha: 0.2,
            angle: -15
          })
        })
      })
    },
    updateChart() {
      this.$refs[this.tabs[this.tabActive].componentName].getchartDatas()
    },
    getStudio() {
      this.$api.crm_filter_stduio()
        .then(res => {
          this.studioColumns = res.data.map(item => ({ label: item.filter_name, value: item.studio_id }))
          this.studioColumns.unshift({label:'全部校区',value:''})
        })
    },
    // 校区
    onStudioPickerClose() {
      this.studioShowPicker = false
    },
    onStudioPickerOpen() {
      this.studioShowPicker = true
    },
    onStudioPickerConfirm(value) {
      this.studio = value
      this.onStudioPickerClose()
      this.updateChart()
    },
    // 开始结束时间
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      } else {
        return `${val}日`
      }
    },
    onDatePickerOpen(type) {
      console.log(type)
      if(type == 'startDate'){
        this.currentTime = this.currentStart
      } else{
        this.currentTime = this.currentEnd
      }
      this.dateType = type
      this.dateShowPicker = true
    },
    onDatePickerClose() {
      this.dateShowPicker = false
    },
    onDatePickerConfirm(time) {
      if (this.dateType === 'endDate') {
        this.currentEnd = time;
        const timestampEnd = dayjs(time).valueOf()
        const timestampStart = dayjs(this.startDate).valueOf()
        if (timestampEnd < timestampStart) {
          this.$toast('结束时间不能小于开始时间')
          return
        }
      }else{
        this.currentStart = time;
      }
      this[this.dateType] = dayjs(time).format('YYYY-MM-DD')
      this.onDatePickerClose()
      this.updateChart()
    },
    // 时间区间推荐
    onTagClick(index) {
      this.tagActive = index
      const { startDate, endDate } = this.tagList[index]
      this.startDate = startDate
      this.endDate = endDate
      this.updateChart()
    }
  }
}
</script>

<style lang="less" scoped>
  .van-tag--primary{
      background-color: #00DE93;
    }

  .container{
    padding: 10px 0;
  }

  .grid-title{
    font-size: 16px;
    color: #333;
  }

  .tab-container{
    margin-top: 10px;
  }

  .section{
    background: #fff;

    &-row{
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &-row2{
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    &-studio{
      box-sizing: border-box;
      width: 120px;
      height: 30px;
      border-radius: 4px;
      padding: 4px 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #666;
      font-size: 14px;
      border: 1px solid #eee;
       justify-content: space-between;
    }
    &-date{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-start{
        margin-left: 10px;
        box-sizing: border-box;
        width: 100px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-size: 12px;
        border: 1px solid #eee;
      }
      &-end{
        margin-left: 10px;
        box-sizing: border-box;
        width: 100px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-size: 12px;
        border: 1px solid #eee;
      }
    }
    &-commend{
      border: 1px solid #00DE93;
      border-radius: 16px;
      overflow: hidden;
    .van-tag{
        padding: 4px 20px;
        font-size: 16px;
        border-radius: 0;
    }
    .van-tag::after{
      border: none;
      border-radius:none;
    }
    .van-tag--plain{
      color: #00DE93;
      background-color: #fff;
    }
  }
  }
</style>
<style lang="less">
  .gwn{
    min-height: 100vh;
    .v-table-views{
      background-color: rgba(0,0,0,0) !important;
    }
    .v-table-header{
      background-color: rgba(0,0,0,0) !important;
    }
  }
</style>

