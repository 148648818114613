<template>
  <div class="crm-today">
    <div>
      <ve-table
        v-if="!loading"
        :columns="columns" 
        :table-data="tableData"
        style="width:100%" 
        :scroll-width="400"
      />
      <div v-show="tableData.length == 0 && !loading" class="v-table-empty-data">暂无数据</div>
    </div>
    <loadings :loading='loading' />
    <copyright />
  </div>
</template>
<script>

import Loadings from '@/components/loading'
import Copyright from '@/components/Copyright'
export default {
  name: 'AnalysisLeads',
  data() {
    return {
      loading:false,
      tableData: [],
      columns: [
          {field: 'rank', key:'rank', title: ' 排名', width: 60, fixed: "left"},
          {field: 'employee_name', key:'employee_name', title: '员工名称', width: 110,fixed: "left"},
          {field: 'course_count', key:'course_count', title: '排课数', width: 90,},
          {field: 'course_student_count', key:'course_student_count', title: '上课学员', width: 90,},
          {field: 'course_times_count', key:'course_times_count', title: '扣课', width: 90,},
          {field: 'course_revenue_money_count', key:'course_revenue_money_count', title: '课消金额', width: 90,},
      ]
    }
  },
  components: {
    Copyright,
    loadings:Loadings,
  },
  created() {
    this.getchartDatas()
  },
  methods: {
    getchartDatas() {
      this.loading = true
      const parentPageObj = this.$parent
      const start_date = parentPageObj.startDate !== '' ? parentPageObj.startDate : ''
      const end_date = parentPageObj.endDate !== '' ? parentPageObj.endDate : ''
      const studio_id = parentPageObj.studio.value !== -1 ? parentPageObj.studio.value : ''
      this.$api.analysis_campus_teacher({ start_date, end_date, studio_id })
        .then(res => {
          this.tableData = res.data
          let tableData = res.data
          let course_count = 0
          let course_student_count=0
          let course_times_count=0
          let course_revenue_money_count=0
          tableData.forEach(item=>{
            course_revenue_money_count = (Number(course_revenue_money_count) + Number(item.course_revenue_money_count)).toFixed(2)
            course_count = (Number(course_count) + Number(item.course_count))
            course_student_count = (Number(course_student_count) + Number(item.course_student_count))
            course_times_count = (Number(course_times_count) + Number(item.course_times_count)).toFixed(1)
          })
          let obj ={
            employee_name:'合计',
            rank:'--',
            course_count,
            course_student_count,
            course_times_count,
            course_revenue_money_count,
          }
          this.tableData.push(obj)
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
    .copyright{
      margin-top: 25px;
    }
    .chart-box{
        box-sizing: border-box;
        background: #fff;
        margin-bottom: 20px;
        padding: 0 15px;
    }

    .chart{
        &-title{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            font-size: 16px;
            color: #333;
            font-weight: bold;
        }
        &-right{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            .section-commend{
              border: 1px solid #00DE93;
              border-radius: 16px;
              overflow: hidden;
            }
            .van-tag::after{
              border: none;
              border-radius:none;
            }
            .van-tag{
                padding: 4px 10px;
                font-size: 14px;
                border-radius: 0;
            }
        }
    }
</style>

<style lang="less">
  .v-table-empty-data{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 100%;
    color: #666;
    font-size: 14px;
    border: 1px solid #eee;
    border-top: 0;
  }
</style>

