<template>
  <div class="crm-today">
    <div>
      <ve-table
        v-if="!loading"
        :columns="columns" 
        :table-data="tableData"
        style="width:100%" 
        :scroll-width="400"
      />
      <div v-show="tableData.length == 0 && !loading" class="v-table-empty-data">暂无数据</div>
    </div>
    <loadings :loading='loading' />
    <copyright />
  </div>
</template>
<script>

import Loadings from '@/components/loading'
import Copyright from '@/components/Copyright'
export default {
  name: 'AnalysisLeads',
  data() {
    return {
      loading:false,
      tableData: [],
      columns: [
          {field: 'studio_name', key:'studio_name', title: '校区名称', width: 110, fixed: "left",ellipsis: {showTitle: true},},
          {field: 'studio_leads', key:'studio_leads', title: '新增例子', width: 90, },
          {field: 'studio_customers', key:'studio_customers', title: '新增客户', width: 90, },
      ]
    }
  },
  components: {
    Copyright,
    loadings:Loadings,
  },
  created() {
    this.getchartDatas()
  },
  methods: {
    getchartDatas() {
      this.loading = true
      const parentPageObj = this.$parent
      const start_date = parentPageObj.startDate !== '' ? parentPageObj.startDate : ''
      const end_date = parentPageObj.endDate !== '' ? parentPageObj.endDate : ''
      const studio_id = parentPageObj.studio.value !== -1 ? parentPageObj.studio.value : ''
      this.$api.analysis_campus_mkt({ start_date, end_date, studio_id })
        .then(res => {
          this.tableData = res.data
          let tableData = res.data
          let total = 0
          let studio_leads = 0
          let studio_customers = 0
          tableData.forEach(item=>{
            // total = (Number(total) + Number(item.total)).toFixed(2)
            studio_leads = (Number(studio_leads) + Number(item.studio_leads))
            studio_customers = (Number(studio_customers) + Number(item.studio_customers))
          })
          let obj ={
            studio_name:'合计',
            // total,
            studio_leads,
            studio_customers
          }
          this.tableData.push(obj)
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
    .copyright{
      margin-top: 25px;
    }
    .chart-box{
        box-sizing: border-box;
        background: #fff;
        margin-bottom: 20px;
        padding: 0 15px;
    }

    .chart{
        &-title{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            font-size: 16px;
            color: #333;
            font-weight: bold;
        }
        &-right{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            .section-commend{
              border: 1px solid #00DE93;
              border-radius: 16px;
              overflow: hidden;
            }
            .van-tag::after{
              border: none;
              border-radius:none;
            }
            .van-tag{
                padding: 4px 10px;
                font-size: 14px;
                border-radius: 0;
            }
        }
    }
</style>

<style lang="less">
  .v-table-empty-data{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 100%;
    color: #666;
    font-size: 14px;
    border: 1px solid #eee;
    border-top: 0;
  }
</style>

